import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class ServerGuard {
  constructor(
    private state: StateService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const servers = this.state.get('servers');
    const current = this.state.get('selectedServer');

    if (servers?.length == 0) {
      return this.router.parseUrl('/profile');
    }

    if (current !== null) {
      if (current?.status === 'terminated') {
        return this.router.parseUrl('/profile');
      }
    }

    return servers?.length > 0;
  }
}
